<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :allChecked="allChecked"
          :checked="checked"
          @markAll="markAll"
          :columns="table.header" />

        <main class="Table__body">
          <div
            class="TableItem TableItem--event"
            v-for="item in chunkedList[this.listQuery.page - 1]"
            :key="item.id">
            <div class="TableColumn TableColumn--check" v-if="checked">
              <Checkbox
                :name="item.name"
                :value="item.id"
                v-model="selectedItems"></Checkbox>
            </div>
            <router-link
              :to="{ name: 'dashboard', params: { id: item.id } }"
              class="TableLink">
              <div class="TableColumn TableColumn--medium text-bold md-w-100">
                {{ item.name }}
              </div>
              <div class="TableColumn TableColumn--short md-w-50">
                {{ convertTime(item.date) | moment('DD/MM/YYYY') }}
              </div>
              <div class="TableColumn TableColumn--flex md-w-50">
                {{ item.address }}
              </div>
              <div class="TableColumn TableColumn--medium">
                <tag-item :type="item.status">
                  <div v-if="item.is_archived">ARCHIVED</div>
                  <div v-else>
                    {{ item.status | capitalize }}
                  </div>
                </tag-item>
              </div>
              <div class="TableColumn TableColumn--medium">
                <span v-if="item.registration_end">{{
                  convertTime(item.registration_end) | moment('DD/MM/YYYY')
                }}</span>
                <span v-else>Not defined</span>
              </div>
            </router-link>
            <div class="TableColumn TableColumn--large url">
              {{ url }}/r/{{ item.slug }}
            </div>
          </div>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"></Pagination>
    </footer>
  </div>
</template>

<script>
  import Checkbox from '@/partials/Forms/Checkbox';

  // Mixins
  import TableMixin from '@/mixins/TableMixin';
  import TimeHelper from '@/helpers/TimeHelper';

  export default {
    name: 'EventsTable',
    mixins: [TableMixin],
    components: {
      Checkbox,
    },
    data: function () {
      return {
        table: {
          header: [
            {
              label: 'Event Name',
              sorted: true,
              sortProp: 'name',
              size: 'medium',
            },
            {
              label: 'Date',
              sorted: true,
              sortProp: 'date',
              size: 'short',
            },
            {
              label: 'Address',
              sorted: true,
              sortProp: 'address',
              size: 'flex',
            },
            {
              label: 'Status',
              size: 'medium',
              sorted: true,
              sortProp: 'status',
            },
            {
              label: 'Registration closes on',
              size: 'medium',
            },
            {
              label: 'Registration link',
              size: 'large',
            },
          ],
        },
      };
    },
    methods: {
      convertTime: TimeHelper.convertUtcToLocal,
    },
  };
</script>
