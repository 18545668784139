<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :allChecked="allChecked"
          :checked="checked"
          @markAll="markAll"
          :columns="table.header" />

        <main class="Table__body">
          <div
            class="TableItem"
            v-for="item in chunkedList[this.listQuery.page - 1]"
            :key="item.id">
            <div class="TableColumn TableColumn--check" v-if="checked">
              <Checkbox
                :name="item.name"
                :value="item.id"
                v-model="selectedItems"></Checkbox>
            </div>
            <div class="TableColumn TableColumn--medium text-semi">
              {{ item.team.name }}
            </div>
            <div class="TableColumn TableColumn--medium">
              {{ item.name }}
            </div>
            <div class="TableColumn TableColumn--short">
              {{ convertTime(item.date) | moment('DD/MM/YYYY') }}
            </div>
            <div class="TableColumn TableColumn--flex">
              {{ item.address }}
            </div>
            <div class="TableColumn TableColumn--large url">
              <tag-item :type="item.submission_status">
                {{
                  item.submission_status === 'pending'
                    ? 'Registered'
                    : item.submission_status | capitalize
                }}
              </tag-item>
            </div>
          </div>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"></Pagination>
    </footer>
  </div>
</template>

<script>
  import Checkbox from '@/partials/Forms/Checkbox';

  // Mixins
  import TableMixin from '@/mixins/TableMixin';
  import TimeHelper from '@/helpers/TimeHelper';

  export default {
    name: 'PlayerEventsTable',
    mixins: [TableMixin],
    components: {
      Checkbox,
    },
    data: function () {
      return {
        table: {
          header: [
            {
              label: 'Team Name',
              sorted: true,
              sortProp: 'team.name',
              size: 'medium',
            },
            {
              label: 'Event Name',
              sorted: true,
              sortProp: 'name',
              size: 'medium',
            },
            {
              label: 'Date',
              sorted: true,
              sortProp: 'date',
              size: 'short',
            },
            {
              label: 'Address',
              sorted: true,
              sortProp: 'address',
              size: 'flex',
            },
            {
              label: 'Registration status',
              sorted: true,
              sortProp: 'submission_status',
              size: 'large',
            },
          ],
        },
      };
    },
    methods: {
      convertTime: TimeHelper.convertUtcToLocal,
    },
  };
</script>
