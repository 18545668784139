<template>
  <div class="Dashboard">
    <dashboard-top>
      <screen-title
        :title="this.$store.getters.teamName"
        action="events/new"
        :actionPermission="userIs('admin')"
        button="Create Event"
        v-if="userNot('player')"
      ></screen-title>
      <screen-title title="My Events" v-else></screen-title>
    </dashboard-top>

    <div class="container-full">
      <div class="dashboard__section">
        <section-title
          :title="userNot('player') ? 'Selection Events' : 'My Submissions'"
        >
          <action
            icon="rubbish-bin"
            @actionClicked="handleDelete"
            v-if="userIs('admin') && events && events.length > 0"
          ></action>
          <action
            icon="duplicate"
            @actionClicked="handleDuplicate"
            v-if="userIs('admin') && events && events.length > 0"
          ></action>
        </section-title>

        <div v-if="userIs('player')">
          <fade-transition mode="out-in">
            <TableLoader v-if="!loaded" />

            <PlayerEventsTable
              :items="events"
              v-if="loaded && events.length > 0"
              :key="events.length + Math.random()"
            ></PlayerEventsTable>

            <empty-message v-if="loaded && events.length == 0">
              No events found
            </empty-message>
          </fade-transition>
        </div>
        <div v-else>
          <tabs>
            <tab title="Active events">
              <fade-transition mode="out-in">
                <TableLoader v-if="!loaded" />

                <EventsTable
                  checked
                  :items="events.filter((item) => !item.is_archived)"
                  v-if="
                    loaded &&
                    userNot('player') &&
                    events.filter((item) => !item.is_archived).length > 0
                  "
                  @selection="handleSelection"
                  :key="events.length + Math.random()"
                ></EventsTable>
                <empty-message
                  v-if="
                    loaded &&
                    events.filter((item) => !item.is_archived).length == 0
                  "
                >
                  No active events found
                </empty-message>
              </fade-transition>
            </tab>
            <tab title="Archived events">
              <fade-transition mode="out-in">
                <TableLoader v-if="!loaded" />

                <EventsTable
                  checked
                  :items="events.filter((item) => item.is_archived)"
                  v-if="
                    loaded &&
                    userNot('player') &&
                    events.filter((item) => item.is_archived).length > 0
                  "
                  @selection="handleSelection"
                  :key="events.length + Math.random()"
                ></EventsTable>
                <empty-message
                  v-if="
                    loaded &&
                    events.filter((item) => item.is_archived).length == 0
                  "
                >
                  No archived events found
                </empty-message>
              </fade-transition>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import EventsTable from '@/components/Tables/EventsTable';
  import PlayerEventsTable from '@/components/Tables/PlayerEventsTable';
  import TableLoader from '@/components/Loaders/TableLoader';
  export default {
    name: 'Events',
    components: {
      EventsTable,
      TableLoader,
      PlayerEventsTable,
    },
    data: function () {
      return {
        selected: [],
      };
    },
    beforeMount() {
      if (!this.$store.state.events.all) {
        this.$store.dispatch('events/GET_EVENTS', this);
      }
    },
    methods: {
      handleSelection(payload) {
        this.selected = payload;
      },
      handleDelete() {
        if (this.selected.length > 0) {
          let remove;
          if (
            this.events.find((item) => item.id == this.selected[0]).is_archived
          ) {
            remove = confirm('Are you sure you want to delete checked events?');
          } else {
            remove = confirm(
              'Are you sure you want to archive checked events?',
            );
          }
          if (remove) {
            this.$store.dispatch('events/DELETE_EVENTS', this.selected);
            this.selected = [];
          }
        } else {
          this.$notify({
            group: 'general',
            title: 'No event selected to remove',
          });
        }
      },
      handleDuplicate() {
        if (this.selected.length > 0) {
          this.$store.dispatch('events/DUPLICATE_EVENTS', this.selected);
          this.selected = [];
        } else {
          this.$notify({
            group: 'general',
            title: 'No event selected to duplicate',
          });
        }
      },
    },
    computed: {
      ...mapState({
        events: (state) => state.events.all,
        loaded: (state) => state.events.status.all == 'success',
      }),
    },
  };
</script>
