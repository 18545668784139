var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Dashboard"},[_c('dashboard-top',[(_vm.userNot('player'))?_c('screen-title',{attrs:{"title":this.$store.getters.teamName,"action":"events/new","actionPermission":_vm.userIs('admin'),"button":"Create Event"}}):_c('screen-title',{attrs:{"title":"My Events"}})],1),_c('div',{staticClass:"container-full"},[_c('div',{staticClass:"dashboard__section"},[_c('section-title',{attrs:{"title":_vm.userNot('player') ? 'Selection Events' : 'My Submissions'}},[(_vm.userIs('admin') && _vm.events && _vm.events.length > 0)?_c('action',{attrs:{"icon":"rubbish-bin"},on:{"actionClicked":_vm.handleDelete}}):_vm._e(),(_vm.userIs('admin') && _vm.events && _vm.events.length > 0)?_c('action',{attrs:{"icon":"duplicate"},on:{"actionClicked":_vm.handleDuplicate}}):_vm._e()],1),(_vm.userIs('player'))?_c('div',[_c('fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.loaded)?_c('TableLoader'):_vm._e(),(_vm.loaded && _vm.events.length > 0)?_c('PlayerEventsTable',{key:_vm.events.length + Math.random(),attrs:{"items":_vm.events}}):_vm._e(),(_vm.loaded && _vm.events.length == 0)?_c('empty-message',[_vm._v(" No events found ")]):_vm._e()],1)],1):_c('div',[_c('tabs',[_c('tab',{attrs:{"title":"Active events"}},[_c('fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.loaded)?_c('TableLoader'):_vm._e(),(
                  _vm.loaded &&
                  _vm.userNot('player') &&
                  _vm.events.filter(function (item) { return !item.is_archived; }).length > 0
                )?_c('EventsTable',{key:_vm.events.length + Math.random(),attrs:{"checked":"","items":_vm.events.filter(function (item) { return !item.is_archived; })},on:{"selection":_vm.handleSelection}}):_vm._e(),(
                  _vm.loaded &&
                  _vm.events.filter(function (item) { return !item.is_archived; }).length == 0
                )?_c('empty-message',[_vm._v(" No active events found ")]):_vm._e()],1)],1),_c('tab',{attrs:{"title":"Archived events"}},[_c('fade-transition',{attrs:{"mode":"out-in"}},[(!_vm.loaded)?_c('TableLoader'):_vm._e(),(
                  _vm.loaded &&
                  _vm.userNot('player') &&
                  _vm.events.filter(function (item) { return item.is_archived; }).length > 0
                )?_c('EventsTable',{key:_vm.events.length + Math.random(),attrs:{"checked":"","items":_vm.events.filter(function (item) { return item.is_archived; })},on:{"selection":_vm.handleSelection}}):_vm._e(),(
                  _vm.loaded &&
                  _vm.events.filter(function (item) { return item.is_archived; }).length == 0
                )?_c('empty-message',[_vm._v(" No archived events found ")]):_vm._e()],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }